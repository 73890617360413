<!-- 测试开始 -->

<template>
  <div class="test">11</div>
</template>

<script>
export default {};
</script>

<style>
.test {
  min-height: 100vh;
  background-color: #f4fafe;
}
</style>
